import React, { Component } from 'react';
import { Switch, Route, Redirect, NavLink, Link } from 'react-router-dom';

import Products from './AdminPages/Products';
import ProductCategories from './AdminPages/ProductCategories';
import Orders from './AdminPages/Orders';
import Messages from './AdminPages/Messages';

import { isAuthenticatedAdmin } from '../services/api';

function PrivateRoute({ component: Component, ...rest }) {
  return <Route {...rest} render={props => (
    isAuthenticated() ? (
      <Component {...props} />
    ) : (
        <Redirect to={{
          pathname: '/login-signup',
          state: { from: props.location }
        }} />
      )
  )} />
}

function isAuthenticated() {
  return isAuthenticatedAdmin();
}

const categorySubcategoryList = [
  {
    id: "products",
    name: "Products",
    enabled: true,
    component: Products
  },
  {
    id: "product-categories",
    name: "Product Categories",
    enabled: true,
    component: ProductCategories
  },
  {
    id: "orders",
    name: "Orders",
    enabled: true,
    component: Orders
  },
  {
    id: "messages",
    name: "Messages",
    enabled: true,
    component: Messages
  },
];

export default class AdminLayout extends Component {

  render() {
    return (
      <div className="App">
        <Header />
        <div id="admin-content">
          <div className="row">
            <Sidebar />
            <div className="col-md-9">
              <Switch>
                {
                  categorySubcategoryList.map(category =>
                    category.subcategories
                      ? category.subcategories.map(subcategory =>
                        subcategory.enabled === true && <PrivateRoute key={`${category.id}-${subcategory.id}`}
                          path={`/admin/${category.id}/${subcategory.id}`}
                          name={`${category.id}-${subcategory.id}`}
                          component={subcategory.component} />
                      )
                      : category.enabled === true && <PrivateRoute key={category.id}
                        path={`/admin/${category.id}`}
                        name={`${category.id}`}
                        component={category.component} />
                  )
                }
                
                <Redirect from="/admin" to="/admin/products" />
              </Switch>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

function Header() {
  return (
    <header className="row" style={{ backgroundColor: "#825c5c", padding: 10, color: "#fff" }}>
      <div className="col-md-6"><h4 style={{ color: "#fff" }}>Mass Exports - Admin</h4></div>
      <div className="col-md-6">
        <div className="pull-right">
          <Link to={"/"}><button className="btn btn-default">Go to main site</button></Link>
          <Link to={"/logout"}><button style={{ marginLeft: "10px" }} className="btn btn-default">Logout</button></Link>
        </div>
      </div>
    </header>
  );
}

function Sidebar() {
  return (
    <div className="col-md-3">
      <div className="panel panel-default sidebar-menu with-icons">

        <div className="panel-body">
          <ul className="nav nav-pills nav-stacked category-menu">
            {
              categorySubcategoryList.map(category =>
                category.subcategories
                  ? <li key={category.id}>
                    <a>{category.name}</a>
                    <ul>
                      {
                        category.subcategories.map(subcategory =>
                          subcategory.enabled === true && <li key={subcategory.id}>
                            <NavLink to={`/admin/${category.id}/${subcategory.id}`} activeStyle={{ backgroundColor: "#ccc", color: "#000" }}>{subcategory.name}</NavLink>
                          </li>
                        )
                      }
                    </ul>
                  </li>
                  : category.enabled === true && <li key={category.id}>
                    <NavLink to={'/admin/' + category.id} activeStyle={{ backgroundColor: "#ccc", color: "#000" }}>{category.name}</NavLink>
                  </li>
              )
            }
          </ul>
        </div>
      </div>
    </div>
  );
}

function Footer() {
  return (
    <footer>
      <div style={{ backgroundColor: "#825c5c", padding: 10, color: "#fff" }} className="row text-center">
        &copy; 2024 Haleelu. All rights reserved
      </div>
    </footer>
  );
}
